<template>
  <div v-if="isList" class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>增值税申报</span>
        </div>
        <div class="select-items">
          <span class="btn" @click="handleManualAdd">
            <img src="@/assets/icon/goodsInfo/insert.png"/>手动添加
          </span>
        </div>
      </div>
      <div class="select-content">
        <el-form ref="selectForm" :model="selectForm" class="select-form" label-width="98px"
                 label-position="left">
          <div>
            <el-form-item prop="declarePeriod" label="所属税期">
              <el-date-picker v-model="selectForm.declarePeriod"
                              type="month"
                              value-format="yyyyMM"
                              :picker-options="dateOption"
                              style="width: 200px;"
                              placeholder="选择所属税期">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="orgId" label="纳税主体名称">
              <organization-select :model="selectForm"></organization-select>
            </el-form-item>
            <el-form-item prop="declareStatus" label="申报状态">
              <el-select v-model="selectForm.declareStatus" placeholder="请选择">
                <el-option
                    v-for="item in declareStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item prop="taxCategory" label="纳税税种">
              <el-select v-model="selectForm.taxCategory" placeholder="请选择">
                <el-option
                    v-for="item in declareTaxCategoryList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="declareMode" label="申报类型">
              <el-select v-model="selectForm.declareMode">
                <el-option
                    v-for="item in declareModeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="tntDeclareId" label="模板名称">
              <el-select v-model="selectForm.tntDeclareId">
                <el-option v-for="item in tntDeclareList"
                           :key="item.id"
                           :label="item.tmplName"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button show-status @list-close="selectExpended = false"
                         @list-show="selectExpended = true" @select="submitForm"
                         @reset="handleReset"></select-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div class="content-table">
        <div class="content-table">
          <el-table
              :data="tableData"
              stripe
              border
              v-loading="tableLoading"
              :row-key="getRowKeys"
              :header-cell-style="handleHeaderCellStyle"
              style="width: 100%;margin-bottom: 20px;"
              :cell-style="cellStyle"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          >
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="declarePeriod" label="所属税期" type="" width="180"></el-table-column>
            <el-table-column prop="orgName" label="纳税主体名称" min-width="260"></el-table-column>
            <el-table-column prop="taxCategory" label="纳税税种" width="180"
                             :formatter="taxCategoryFormat"></el-table-column>
            <el-table-column prop="declareStatus" label="申报状态" width="180">
              <template slot-scope="scope">
                <span
                    :style="scope.row.declareStatus != ('302'||'520')?'':'color:#F5222D;'">{{
                    declareStatusFormat(scope.row.declareStatus)
                  }}</span>
              </template>
            </el-table-column>
              <el-table-column prop="creatorName" label="操作人" width="180"></el-table-column>
            <el-table-column prop="operate" label="操作" fixed="right" width="250">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDetail(scope.row, 'manuscript')">详情
                </el-button>
<!--                  只有申报状态为“编辑底稿 300”“提交失败 302”时才可以删除，其余状态此按钮置灰不可点-->
                <el-button v-role="'DEC-TMPL-ENT-QUERY-F06'" type="text"
                           @click="handleDelete(scope.row)":disabled="!(scope.row.declareStatus=='300'||scope.row.declareStatus=='302')"
                >删除
                </el-button>

              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="content-page">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page.sync="page.current"
                         :page-sizes="pageSizes"
                         :page-size="page.size"
                         layout="total, sizes, prev, pager, next"
                         :total="page.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog append-to-body
        title="添加申报信息"
        :visible.sync="dialogVisible"
        width="428px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
    >
      <el-tabs v-model="activeName" @tab-click="handleUploadTabsChange">
        <el-tab-pane label="添加申报表" name="tjsbb" lazy>
          <el-form ref="insertDeclareForm" class="insert-dialog" :model="insertDeclare"
                   :rules="insertDeclareRules"
                   label-position="top">
            <div class="insert-item">
              <el-form-item prop="orgId" label="组织名称" required>
                <organization-select :model="insertDeclare"
                                     @handleSelectAction="handleGetDeclareSettingByInsert"></organization-select>
              </el-form-item>
            </div>
            <div class="insert-item">
              <el-form-item prop="declarePeriod" label="所属税期" required>
                <el-date-picker v-if="isYearDatePicker" v-model="insertDeclare.declarePeriod"
                                type="year"
                                value-format="yyyy"
                                :picker-options="endYearOption"
                                style="width: initial;"
                                placeholder="选择征期">
                </el-date-picker>
                <el-date-picker v-else v-model="insertDeclare.declarePeriod"
                                type="month"
                                value-format="yyyyMM"
                                :picker-options="endDateOption"
                                style="width: initial;"
                                placeholder="选择征期">
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="insert-item">
              <el-form-item prop="taxCategory" label="纳税税种" required>
                <el-select v-model="insertDeclare.taxCategory">
                  <el-option v-for="item in taxCategoryList"
                             :key=item.value
                             :label="item.label"
                             :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
          <div class="dialog-footer">
            <el-button type="default" @click="handleCancelDialog">取消</el-button>
            <el-button type="primary" :loading="addLoading" @click="handleCheckDeclareTmplByInsert"
                       :disabled="insertDisable">添加
            </el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="导入申报表" name="drsbb" lazy>
          <el-form ref="importDeclareForm" class="insert-dialog" :model="uploadDeclare"
                   :rules="importDeclareRules"
                   label-position="top">
            <div class="insert-item">
              <el-form-item prop="orgId" label="组织名称" required>
                <organization-select :model="uploadDeclare"
                                     @handleSelectAction="handleGetDeclareSettingByImport"></organization-select>
              </el-form-item>
            </div>
            <div class="insert-item">
              <el-form-item prop="declarePeriod" label="所属税期" required>
                <el-date-picker v-if="isYearDatePicker" v-model="uploadDeclare.declarePeriod"
                                type="year"
                                value-format="yyyy"
                                :picker-options="endYearOption"
                                style="width: initial;"
                                placeholder="选择征期">
                </el-date-picker>
                <el-date-picker v-else v-model="uploadDeclare.declarePeriod"
                                type="month"
                                value-format="yyyyMM"
                                :picker-options="endDateOption"
                                style="width: initial;"
                                placeholder="选择征期">
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="insert-item" v-show="false">
              <el-form-item prop="taxCategory" label="纳税税种" required>
                <el-select v-model="uploadDeclare.taxCategory">
                  <el-option v-for="item in taxCategoryList"
                             :key=item.value
                             :label="item.label"
                             :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="insert-item import-item">
              <el-form-item class="form-item" label="导入自定义申报表(增值税)" required>
                <el-input v-model="uploadDeclare.fileName" placeholder="文件名称.xlsx"></el-input>
                <el-upload class="upload" accept=".xlsx" action="#" :multiple="false"
                           :show-file-list="false"
                           :on-progress="uploadFile">
                  <el-button type="default" icon="el-icon-upload2">
                    选择文件
                  </el-button>
                </el-upload>
              </el-form-item>
            </div>
          </el-form>
          <div class="dialog-footer">
            <el-button type="default" @click="handleCancelDialogByImport">取消</el-button>
            <el-button type="primary" :loading="addLoading" @click="handleCheckDeclareTmpl"
                       :disabled="importDisable">添加
            </el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import {
  externalEntList,
  tmplTntList,
  addDeclareEntDeclareExternal,
  uploadDeclareExternal,
  deleteDeclareExternal,
} from '@/service/declare/index'
import {
  getDeclareSetting
} from '@/service/declare/setting.js'
import OrganizationSelect from '@/components/widgets/OrganizationSelect'
import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins'
import LuckyExcel from 'luckyexcel'

export default {
  name: 'DecWbsj',
  mixins: [tableStyle, dateOption, pagingMethods, changeMethods],
  components: { OrganizationSelect },
  watch: {
    '$route.path': {
      handler () {
        this.isList = false
        if (this.$route.path == '/declare/ent/external/list') {
          this.isList = true
          this.submitForm()
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      isList: true,   // 是否列表页
      selectForm: {
        declarePeriod: undefined,
        orgId: 0,
        declareMode: undefined,
        declareStatus: undefined,
        approvalStatus: undefined,
        taxCategory: undefined,
        tntDeclareId: undefined
      },
      // 新增申报表
      insertDeclare: {
        declarePeriod: '',
        //纳税主体名称 组织名称
        orgId: '',
        taxCategory: '',
        //租户申报模板id，企业申报模板在租户申报模板基础上创建
        tntDeclareId: '',
        //计提周期
        accrualPeriod: '',
        //申报类型
        declareMode: '',
        // 租户id
        tenantId: ''
      },
      // 上传申报表
      uploadDeclare: {
        declarePeriod: '',
        //纳税主体名称 组织名称
        orgId: '',
        taxCategory: '',
        //租户申报模板id，企业申报模板在租户申报模板基础上创建
        tntDeclareId: '',
        //计提周期
        accrualPeriod: '',
        //申报类型
        declareMode: '',
        fileName: '',
        raw: null,
        fileName2: '',
        raw2: null,
        sheet: [],
        sheet2: [],
        // 租户id
        tenantId: '',
        isHistory: false
      },
      endDateOption: {
        disabledDate (time) {
          let fullYear = new Date().getFullYear()
          let timeYear = time.getFullYear()
          if (timeYear == fullYear) {
            return time.getMonth() >= new Date().getMonth()
          }
        }
      },
      endYearOption: {
        disabledDate (time) {
          return time.getFullYear() >= new Date().getFullYear()
        }
      },
      tableData: [],
      tableLoading: false,
      hasChildren: true,
      selectExpended: false,
      dialogVisible: false,
      addLoading: false,
      importLoading: false,
      declareTaxCategoryList: [],
      declareModeList: [],
      declareStatusList: [],
      approvalStatusList: [],
      declareResultList: [],
      taxCategoryList: [],
      isYearDatePicker: false,
      insertDisable: true,
      importDisable: true,
      tntDeclareTmpl: [],
      // 添加申报，校验
      insertDeclareRules: {
        orgId: [{ required: true, message: '请选择组织！' }],
        declarePeriod: [{ required: true, message: '请选择所属税期！' }, {
          validator: this.checkDeclarePeriod,
          trigger: 'blur'
        }],
        taxCategory: [{ required: true, message: '请选择纳税税种!' }]
      },
      importDeclareRules: {
        orgId: [{ required: true, message: '请选择组织！' }],
        declarePeriod: [{ required: true, message: '请选择所属税期！' }, {
          validator: this.checkDeclarePeriodByImport,
          trigger: 'blur'
        }],
        taxCategory: [{ required: true, message: '请选择纳税税种!' }]
      },
      tntDeclareList: [],
      activeName: 'tjsbb',
    }
  },
  created () {
    this.submitForm()
    this.initDict()
  },
  methods: {
    // 初始化
    initDict () {
      this.getDicts('dec_tax_category').then(res => {
        if (res.success) {
          this.declareTaxCategoryList = [{ label: '全部', value: undefined }, ...res.data]
          this.taxCategoryList = res.data
        }
      })
      this.getDicts('dec_declare_mode').then(res => {
        if (res.success)
          this.declareModeList = [{ label: '全部', value: undefined }, ...res.data]
      })
      this.getDicts('dec_declare_status').then(res => {
        if (res.success)
          this.declareStatusList = [{ label: '全部', value: undefined }, ...res.data]
      })
      this.getDicts('dec_approval_status').then(res => {
        if (res.success)
          this.approvalStatusList = [{ label: '全部', value: undefined }, ...res.data]
      })
      this.getDicts('dec_declare_result').then(res => {
        if (res.success)
          this.declareResultList = [{ label: '全部', value: undefined }, ...res.data]
      })
      //获取租户模板 筛选
      tmplTntList({ page: 1, size: 100 }).then(res => {
        if (res.success)
          this.tntDeclareList = [{ tmplName: '全部', id: undefined }, ...res.data.records]
      })
    },
    // 查询
    submitForm () {
      this.selectForm.page = this.page.current
      this.selectForm.size = this.page.size
      this.tableLoading = true
      externalEntList(this.selectForm).then(res => {
        this.tableLoading = false
        if (res.success) {
          this.tableData = res.data.records
          this.page.total = res.data.total
            console.log("tableData:",this.tableData)
        }
      })
    },
    // 手动添加
    handleManualAdd () {
      this.dialogVisible = true
      this.insertDeclare.taxCategory = this.taxCategoryList[0].value
      this.uploadDeclare.taxCategory = this.taxCategoryList[0].value
    },
    // 上传文件 解析 xlsx
    uploadFile (event, file) {
      if (file == null || file.length == 0) {
        this.toast('上传文件失败', 'warning')
        return
      }
      if (/^xlsx/.test(file.name)) {
        this.toast('上传文件非xlsx文件，请重新选择', 'warning')
        return
      }
      this.uploadDeclare.fileName = file.name
      this.uploadDeclare.raw = file.raw
    },
    // 上传文件 解析 xlsx
    uploadFile2 (event, file) {
      if (file == null || file.length == 0) {
        this.toast('上传文件失败', 'warning')
        return
      }
      if (/^xlsx/.test(file.name)) {
        this.toast('上传文件非xlsx文件，请重新选择', 'warning')
        return
      }
      this.uploadDeclare.fileName2 = file.name
      this.uploadDeclare.raw2 = file.raw
    },
    // 添加申报信息校验--导入
    handleCheckDeclareTmpl () {
      this.$refs.importDeclareForm.validate(valid => {
        if (valid) this.handleAddDeclareTmplByImport()
      })
    },
    // 添加申报信息校验--插入
    handleCheckDeclareTmplByInsert () {
      this.$refs.insertDeclareForm.validate(valid => {
        if (valid) this.handleAddDeclareTmplByInsert()
      })
    },
    // 执行 添加申报模板
    async handleAddDeclareTmplByInsert () {
      this.addLoading = true
      const { success } = await addDeclareEntDeclareExternal(this.insertDeclare)
      this.addLoading = false
      if (success) this.toast('添加模版成功', 'success', () => {
        this.handleCancelDialog()
      })
    },
    // 执行 添加申报模板
    async handleAddDeclareTmplByImport () {
      this.importLoading = true
      if (this.uploadDeclare.fileName && this.uploadDeclare.raw != null) {
        console.log(this.uploadDeclare)
        LuckyExcel.transformExcelToLucky(this.uploadDeclare.raw, exportJSON => {
          if (exportJSON.sheets == null || exportJSON.sheets.length == 0) {
            this.toast('增值税未解析到表单数据，请确认上传的表单是否异常', 'warning')
            return
          }
          this.uploadDeclare.sheets = exportJSON.sheets
          console.log('增值税：', this.uploadDeclare.sheets)
          if (this.uploadDeclare.fileName2 && this.uploadDeclare.raw2 != null) {
            LuckyExcel.transformExcelToLucky(this.uploadDeclare.raw2, exportJSON2 => {
              if (exportJSON2.sheets == null || exportJSON2.sheets.length == 0) {
                this.toast('附加税未解析到表单数据，请确认上传的表单是否异常', 'warning')
                return
              }
              console.log('附加税拼接前：', exportJSON2.sheets[0])
              exportJSON2.sheets[0].name = '附加税'
              this.uploadDeclare.sheets.push(exportJSON2.sheets[0])
              console.log('附加税2：', this.uploadDeclare.sheets)

              uploadDeclareExternal(this.uploadDeclare).then(({ success }) => {
                this.importLoading = false
                if (success) this.toast('模版导入成功', 'success', () => {
                  this.handleCancelDialogByImport()
                })
              })
            })
          } else {
            uploadDeclareExternal(this.uploadDeclare).then(({ success }) => {
              this.importLoading = false
              if (success) this.toast('模版导入成功', 'success', () => {
                this.handleCancelDialogByImport()
              })
            })
          }
        })
      } else {
        this.toast('请选择要导入的申报表', 'warning', () => {
        })
        this.importLoading = false
      }
    },
    // 校验所属税期
    checkDeclarePeriod (rule, value, callback) {
      if (!value) return callback(new Error('请选择所属税期！'))
      // 按季度申报
      if (this.insertDeclare.accrualPeriod == 2 && !/03|06|09|12/.test(value.substring(4))) {
        this.toast('当前组织是季度申报只能选择\'3/6/9/12\'月的所属税期', 'warning')
        callback(new Error('选择所属税期错误!'))
      }
      callback()
    },
    // 校验所属税期
    checkDeclarePeriodByImport (rule, value, callback) {
      if (!value) return callback(new Error('请选择所属税期！'))
      // 按季度申报
      if (this.uploadDeclare.accrualPeriod == 2 && !/03|06|09|12/.test(value.substring(4))) {
        this.toast('当前组织是季度申报只能选择\'3/6/9/12\'月的所属税期', 'warning')
        callback(new Error('选择所属税期错误!'))
      }
      callback()
    },
    //获取申报设置信息
    async handleGetDeclareSettingByInsert () {
      this.insertDisable = true
      let orgId = this.insertDeclare.orgId
      if (orgId == '' || orgId == undefined) return
      const { success, data } = await getDeclareSetting(orgId)
      if (success && data != null) {
        this.insertDeclare.accrualPeriod = data.nsqxdm
        this.insertDeclare.tntDeclareId = data.declareTmplId
        this.insertDeclare.declareMode = data.declareMode
        this.insertDeclare.tenantId = data.tenantId
        this.isYearDatePicker = data.nsqxdm == 4 ? true : false
        // 没有租户底稿，置灰按钮
        if (data.declareTmplId) {
          this.insertDisable = false
        } else {
          this.toast('所选组织未找到租户底稿！', 'warning')
        }
      }
    },
    //获取申报设置信息
    async handleGetDeclareSettingByImport () {
      this.importDisable = true
      let orgId = this.uploadDeclare.orgId
      if (orgId == '' || orgId == undefined) return
      const { success, data } = await getDeclareSetting(orgId)
      console.log(data)
      if (success && data != null) {
        this.uploadDeclare.accrualPeriod = data.nsqxdm
        this.uploadDeclare.tntDeclareId = data.declareTmplId
        this.uploadDeclare.declareMode = data.declareMode
        this.uploadDeclare.tenantId = data.tenantId
        this.isYearDatePicker = data.nsqxdm == 4 ? true : false
        // 没有租户底稿，置灰按钮
        if (data.declareTmplId) {
          this.importDisable = false
        } else {
          this.toast('所选组织未找到租户底稿！', 'warning')
        }
      }
    },
    // 查看详情
    handleDetail (row, type) {
      if (!row.id) {
        this.toast('当前记录无对应的底稿！', 'warning')
        return
      }
      this.isList = false
      this.$router.replace({
        path: '/declare/ent/external/detail',
        query: {
          id: row.id,
          type: type
        }
      })
    },
    extractHeaderValue (headers, key) {
      let value = headers[key]
      if (value) return value
      value = headers[key.toLowerCase()]
      return value
    },
    // 删除底稿和申报表
    handleDelete (row) {
      this.$confirm('是否删除申报信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDeclareExternal(row.id).then(({ success }) => {
          if (success) this.toast('删除申报表成功', 'success')
          this.submitForm()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 重置查询条件
    handleReset () {
      this.$refs.selectForm.resetFields()
    },
    // 重置添加模板
    handleResetAddForm () {
      this.$refs.importDeclareForm.resetFields()
      this.uploadDeclare.fileName = ''
      this.uploadDeclare.raw = null
      this.uploadDeclare.sheet = []
      this.uploadDeclare.fileName2 = ''
      this.uploadDeclare.raw2 = null
      this.uploadDeclare.sheet2 = []
    },
    // 获取row-key
    getRowKeys (row) {
      return row.declarePeriod + row.id + row.orgId
    },
    // 取消弹窗
    handleCancelDialog () {
      this.$refs.insertDeclareForm.resetFields()
      this.dialogVisible = false
      this.submitForm()
    },
    handleCancelDialogByImport () {
      this.handleResetAddForm()
      this.dialogVisible = false
      this.submitForm()
    },
    // 纳税税种格式化
    taxCategoryFormat (row) {
      return this.getDictLabel(this.declareTaxCategoryList, row.taxCategory)
    },
    // 申报类型
    declareModeFormat (row) {
      return this.getDictLabel(this.declareModeList, row.declareMode)
    },
    // 申报状态
    declareStatusFormat (value) {
      return this.getDictLabel(this.declareStatusList, value)
    },
    declareResultFormat (value) {
      return this.getDictLabel(this.declareResultList, value)
    },
    // 单元列格式
    cellStyle ({ columnIndex }) {
      if (columnIndex === 2) {
        return 'text-align:left'
      }
    },
    // 待复核按钮是否禁用
    btnReviewDisabled (row) {

      const history = row.isHistory
      if (history) {
        return true
      }
      const status = row.approvalStatus
      switch (status) {
        case 'UNCOMMITTED':
        case 'REVIEWFAILED':
        case 'APPROVALPENDING':
        case 'APPROVALFAILED':
        case 'APPROVED':
          return true
        case 'UNREVIEWED':
        default:
          return false
      }
    },
    btnApprovalDisabled (row) {
      const history = row.isHistory
      if (history) {
        return true
      }
      const status = row.approvalStatus
      switch (status) {
        case 'UNCOMMITTED':
        case 'UNREVIEWED':
        case 'REVIEWFAILED':
        case 'APPROVALFAILED':
        case 'APPROVED':
          return true
        case 'APPROVALPENDING':
        default:
          return false
      }
    },
    btnReviewVisible (row) {
      let arr = this.$store.state.permission.roles
      if (arr.indexOf('DEC-TMPL-ENT-QUERY-F05') > -1) {
        const status = row.approvalStatus
        let visible = false
        switch (status) {
          case 'UNCOMMITTED':
          case 'UNREVIEWED':
          case 'REVIEWFAILED':
          case 'APPROVALFAILED':
          case 'APPROVED':
            visible = true
            break
          case 'APPROVALPENDING':
          default:
            visible = false
            break
        }
        // console.log("ReviewButton stauts:", status, " visible:", visible)
        return visible
      } else {
        return true
      }
    },
    btnApprovalVisible (row) {
      const status = row.approvalStatus
      let arr = this.$store.state.permission.roles
      if (arr.indexOf('DEC-TMPL-ENT-QUERY-F01') > -1) {
        let visible = false
        switch (status) {
          case 'UNCOMMITTED':
          case 'UNREVIEWED':
          case 'REVIEWFAILED':
          case 'APPROVALFAILED':
          case 'APPROVED':
            visible = false
            break
          case 'APPROVALPENDING':
          default:
            visible = true
            break
        }
        // if (!this.btnReviewVisible(row)) {
        //     visible = true;
        // }
        // console.log("ApprovalButton stauts:", status, " visible:", visible)
        return visible
      } else {
        return true
      }
    },
    // 上传Tabs切换
    handleUploadTabsChange (tab) {
      this.activeName = tab.name
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/select.scss";

.select-header {
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      margin-right: 24px;
    }

    img {
      width: 18px;
      height: 18px;
      vertical-align: sub;
      margin-right: 4px;
    }

    span {
      color: #1890FF;
      line-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }

    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.select-title span:before {
  content: "|";
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  background-color: #333;
}

.content-main {
  .content-title {
    display: flex;
    justify-content: space-between;
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    font-family: Medium;
    margin-bottom: 12px;
  }

  .content-table {
    ::v-deep .el-button {
      padding: 0;
    }

    .approval-status:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.insert-dialog {
  margin-top: 5px;
  display: flex;
  flex-direction: column;

  .insert-item {
    display: flex;
    text-align: left;
    flex-direction: row;
    padding: 0 24px 24px;

    ::v-deep .el-select .el-input,
    ::v-deep .el-input__inner {
      width: 380px;
    }
  }

  .import-item {
    ::v-deep .el-input__inner {
      width: 240px;
    }

    ::v-deep .el-form-item__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .upload {
        padding-left: 50px;
      }
    }
  }
}

.dialog-footer {
  padding: 0 24px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

::v-deep .el-dialog .el-dialog__body {
  padding: 0 0 0 0;
}
</style>
